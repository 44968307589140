import Color from 'color';
import { topBarColor } from 'pages/styles';
import styled from 'styled-components';

export const Container = styled.div<{ isSelected: boolean }>`
  display: inline-flex;
  align-items: center;
  background: #e6e6e6;
  color: #525252;
  padding: 3px 8px;
  gap: 4px;
  border-radius: 5px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    background: ${({ isSelected }) => !isSelected && Color(topBarColor).lighten(0.9).toString()};
  }
  color: ${({ isSelected }) => isSelected && 'white'};
  background: ${({ isSelected }) => isSelected && topBarColor};
`;

export const IconCont = styled.div`
  scale: 0.9;
`;

export const Name = styled.div`
  font-size: 10px;
`;
