import React, { useLayoutEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import * as S from './Loader.styled';

const Loader = function Loader({ useSticky }:{ useSticky?: boolean }) {
  useLayoutEffect(() => {
    const sideBar = document.getElementById('sidebar');
    if (sideBar) {
      sideBar.style.zIndex = '1';
    }
    return () => {
      if (sideBar) {
        sideBar.style.zIndex = '3';
      }
    };
  });

  return (
    <>
      <S.Bg useSticky={useSticky} />
      <S.Progress>
        <CircularProgress color="inherit" />
      </S.Progress>
    </>
  );
};

export default Loader;
