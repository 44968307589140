import styled from 'styled-components';

export const Bg = styled.div<{ useSticky?: boolean }>`
  width: 100vw;
  height: 100svh;
  position: ${({ useSticky }) => useSticky ? 'sticky' : 'fixed'};
  top: 0;
  left: 0;
  background: black;
  opacity: ${({ useSticky }) => useSticky ? '0.2' : '0.8'};
  z-index: 999;
`;

export const Progress = styled.div`
  position: fixed;
  color: yellow;
  z-index: 1000;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
`;
