import React, { useEffect, useState } from 'react';
import * as S from './SearchSection.styled';
import Search from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectBooked,
  selectCart,
  selectDisplayRooms,
  selectFloor,
  selectRooms,
  selectSearch,
  selectSort,
  setDisplayFromRooms,
  setDisplayRooms,
  setFloor, setSearch, setSort,
} from '../redux';
import { selectToken } from 'redux/global';
import { perkOptions } from 'pages/AddEditRoom/components/PerkOption';

export const sortOptions = ['No sort. No filter', 'Name', 'Price', 'Booked', 'Available'];
const floorOptions = ['All floors'];

const SearchSection = function SearchSection() {
  const [asc, setAsc] = useState<boolean>(false);
  const search = useAppSelector(selectSearch);
  const token = useAppSelector(selectToken);
  const [sortOpts, setSortOpts] = useState<string[]>([]);
  const [floorOpts, setFloorOpts] = useState<string[]>(floorOptions);
  const sort = useAppSelector(selectSort);
  const rooms = useAppSelector(selectRooms);
  const displayRooms = useAppSelector(selectDisplayRooms);
  const dispatch = useAppDispatch();
  const booked = useAppSelector(selectBooked);
  const floor = useAppSelector(selectFloor);
  const cart = useAppSelector(selectCart);

  useEffect(() => {
    const newSortOpts = [...sortOptions];
    token && !newSortOpts.includes('On hold') && newSortOpts.push('On hold');
    setSortOpts(newSortOpts);
  }, [token]);

  useEffect(() => {
    if (rooms) {
      let roomsHere = [...rooms.filter((r) => floor === 'All floors' || r.floor === floor.replace('Floor ', ''))];
      if (sort === 'Booked') {
        if (token) {
          roomsHere = roomsHere.filter((r) => +new Date() < +new Date(r.freeBy));
        } else {
          roomsHere = roomsHere.filter((r) => booked.find((b) => r.id === b.id
              && r.bookToken === b.token)
            && +new Date() < +new Date(r.freeBy));
        }
      } else if (sort === 'On hold') {
        roomsHere = roomsHere.filter((r) => r.onHold);
      } else if (sort === 'Available') {
        roomsHere = roomsHere.filter((r) => +new Date() >= +new Date(r.freeBy)
          && !r.onHold && !cart.find((c) => c.id.toString() === r.id.toString()));
      }
      dispatch(setDisplayRooms((roomsHere || []).slice().sort((a, b) => {
        if (sort === 'Name' || sort === 'On hold') {
          if (asc) {
            return b?.name?.toLowerCase() < a?.name?.toLowerCase()
              ? 1
              : -1;
          } else {
            return b?.name?.toLowerCase() < a?.name?.toLowerCase()
              ? -1
              : 1;
          }
        } else if (sort === 'Price' || sort === 'Available') {
          if (asc) {
            return Number(b?.price) < Number(a?.price)
              ? 1
              : -1;
          } else {
            return Number(b?.price) < Number(a?.price)
              ? -1
              : 1;
          }
        } else if (sort === 'Booked') {
          if (asc) {
            return new Date(b?.freeBy) < new Date(a?.freeBy)
              ? 1
              : -1;
          } else {
            return new Date(b?.freeBy) < new Date(a?.freeBy)
              ? -1
              : 1;
          }
        } else {
          if (asc) {
            return new Date(b?.updatedAsOf) < new Date(a?.updatedAsOf)
              ? 1
              : -1;
          } else {
            return new Date(b?.updatedAsOf) < new Date(a?.updatedAsOf)
              ? -1
              : 1;
          }
        }
      })));
    }
  }, [rooms, sort, asc, floorOpts]);

  useEffect(() => {
    if (rooms) {
      const allFloors = [floorOptions[0], ...Array.from(new Set(rooms.map((r) => `Floor ${r.floor}`).sort()))];
      setFloorOpts(allFloors);
      const newRooms = [...rooms.filter((r) => floor === 'All floors' || r.floor === floor.replace('Floor ', ''))];
      dispatch(setDisplayFromRooms(newRooms));
    }
  }, [rooms, floor]);

  const Floor: React.FC = () => {
    return (
      <S.Select1
        onChange={(e) => {
          dispatch(setFloor(e.target.value));
          dispatch(setSort(''));
          dispatch(setSearch(''));
        }}
      >
        {
          floorOpts.map((f) => (
            <option key={`floor_${f}`} selected={floor === f}>
              {f}
            </option>
          ))
        }
      </S.Select1>
    );
  };

  const hasPerkOption = (perks: number[], s: string) => {
    let found = false;
    const perkOpts = perkOptions.filter((p, i) => perks.includes(i));
    for (let i = 0; i < perkOpts.length; i += 1) {
      if (perkOpts[i].name.toLowerCase().includes(s)) {
        found = true;
        break;
      }
    }
    return found;
  };

  return (
    <S.Container token={Boolean(token)}>
      <S.SearchCont>
        <S.FlexCont>
          <S.InputCont>
            <Search
              fontSize="medium"
              style={{
                position: 'absolute',
                left: '10px',
                top: '9px',
                color: 'grey',
              }}
            />
            <S.Input
              placeholder={`...Search by ${token ? 'token, name, perk or description' : 'name, perk or description'}`}
              value={search}
              onChange={(e) => {
                const searchHere = e.target.value.toLowerCase();
                dispatch(setSearch(searchHere));
                dispatch(setSort('No sort. No filter'));
                dispatch(setDisplayRooms(
                  [...(rooms || []).filter((r) =>
                    (floor === 'All floors' || floor.replace('Floor ', '') === r.floor) &&
                    ((r.name.toLowerCase().includes(searchHere)
                    || (token && (r.bookToken?.toLowerCase().includes(searchHere.toLowerCase())
                    && +new Date() < +new Date(r.freeBy)))
                    || r.description.toLowerCase().includes(searchHere))
                    || hasPerkOption(r.perks, searchHere)))].reverse()
                ));
              }}
            />
          </S.InputCont>
          <S.FloorCont>
            <Floor />
          </S.FloorCont>
          <S.SortCont>
            <S.Select
              onChange={(e) => {
                dispatch(setSort(e.target.value === 'Last updated' ? 'No sort. No filter' : e.target.value));
                dispatch(setSearch(''));
              }}
            >
              {
                sortOpts.map((s) => (
                  <option key={`sort_${s}`} selected={sort === s}>
                    {s.toLowerCase() === 'no sort. no filter' && token ? 'Last updated' : s}
                  </option>
                ))
              }
            </S.Select>
          </S.SortCont>
        </S.FlexCont>
        <S.OrderBySect1>
          <S.Input11
            type="checkbox"
            checked={asc}
            onChange={() => setAsc(!asc)}
          />
          <S.OrderBy1>Order by asc</S.OrderBy1>
        </S.OrderBySect1>
      </S.SearchCont>
      <S.Result>
        <S.FloorCont1>
          <Floor />
        </S.FloorCont1>
        <S.OrderBySect>
          <S.Input1
            type="checkbox"
            checked={asc}
            onChange={() => setAsc(!asc)}
          />
          <S.OrderBy>Order by asc</S.OrderBy>
        </S.OrderBySect>
        <S.ResultText>
          showing
          {` ${(displayRooms || []).length}`}
          <i>
            {` room${(displayRooms || []).length === 1 ? '' : 's'}`}
          </i>
          <S.Floor>
            {` on ${floor.toLowerCase()}`}
          </S.Floor>
        </S.ResultText>
      </S.Result>
    </S.Container>
  );
};

export default SearchSection;
