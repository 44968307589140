import React from 'react';
import Bed from '@mui/icons-material/Hotel';
import Food from '@mui/icons-material/Restaurant';
import House from '@mui/icons-material/Apartment';
import Balcony from '@mui/icons-material/Balcony';
import Accessible from '@mui/icons-material/Accessible';
import AcUnit from '@mui/icons-material/AcUnit';
import Fridge from '@mui/icons-material/Kitchen';
import Family from '@mui/icons-material/FamilyRestroom';
import Tv from '@mui/icons-material/Tv';
import * as S from './PerkOption.styled';
import { toast } from 'react-toastify';

export const perksMax = 6;

const setPerksCall = (perk: number, perks: number[], setPerks: Function) => {
  if (perks.includes(perk)) {
    setPerks(perks.filter((p) => p !== perk));
    return;
  }
  if (perks.length === perksMax) {
    toast(`you can only set ${perksMax} perks`, { type: 'error' });
  } else {
    const newPerks = [...perks];
    newPerks.push(perk);
    setPerks(newPerks);
  }
};

export const perkOptions = [
  {
    name: 'Breakfast',
    icon: <Food fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Pent house',
    icon: <House fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: '2 beds',
    icon: <Bed fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Large bed',
    icon: <Bed fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Split beds',
    icon: <Bed fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: '1 bed',
    icon: <Bed fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Balcony',
    icon: <Balcony fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Large room',
    icon: <House fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Accessible',
    icon: <Accessible fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'A/C unit',
    icon: <AcUnit fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Big TV',
    icon: <Tv fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Fridge',
    icon: <Fridge fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
  {
    name: 'Family OK',
    icon: <Family fontSize="small" />,
    onClick: (perk: number, perks: number[], setPerks: Function) => {
      setPerksCall(perk, perks, setPerks);
    }
  },
];
const PerkOption = (
  { perk, perks, setPerks }
  :
  { perk: number, perks: number[], setPerks: Function }
) => {
  return (
    <S.Container
      onClick={() => perkOptions[perk].onClick(perk, perks, setPerks)}
      isSelected={perks.includes(perk)}
    >
      <S.IconCont>
        {perkOptions[perk].icon}
      </S.IconCont>
      <S.Name>{perkOptions[perk].name}</S.Name>
    </S.Container>
  );
};

export default PerkOption;
