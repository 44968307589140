import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export interface HomepageState {
  isStaff: boolean,
  isFirstLoad: boolean;
}

const initialState: HomepageState = {
  isStaff: Boolean(localStorage.getItem('isStaff')),
  isFirstLoad: true
};

export const counterSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    setIsStaff: (state, action: PayloadAction<boolean>) => {
      state.isStaff = action.payload;
      if (action.payload) {
        localStorage.setItem('isStaff', 'Yes');
      } else {
        localStorage.removeItem('isStaff');
      }
    },
    setIsFirstLoad: (state, action: PayloadAction<boolean>) => {
      state.isFirstLoad = action.payload;
    },
  },
});

export const { setIsStaff, setIsFirstLoad } = counterSlice.actions;

export const selectIsStaff = (state: RootState) => state.homepage.isStaff;
export const selectIsFirstLoad = (state: RootState) => state.homepage.isFirstLoad;

export default counterSlice.reducer;
