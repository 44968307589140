import { callEndpoint } from 'redux/global';
import { IResponse, RoomType } from 'global/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { sortOptions } from './components/SearchSection';

export type Booked = {
  id: string,
  token: string,
  expires: Date,
}

export type QtConfig = {
  qtToken: string,
  qtExpires: number,
  qtPayableId: string,
  qtJti: string,
}

export interface AppState {
  refreshRooms: boolean,
  rooms: RoomType[] | null,
  displayRooms: RoomType[] | null,
  bookersEmail: string,
  bookersName: string,
  bookDays: string,
  search: string;
  sort: string;
  booked: Booked[];
  userEmail: string;
  password: string;
  isStaySignedIn: boolean;
  isScrollScreen: boolean | null;
  floor: string;
  qtConfig: QtConfig | null;
  isLoadingImages: boolean;
  updatesSeen: string[];
  cart: BookRoomDetails[];
}

const initialState: AppState = {
  refreshRooms: false,
  rooms: null,
  displayRooms: null,
  bookersEmail: localStorage.getItem('bookersEmail') || '',
  bookersName: '',
  bookDays: '1',
  search: '',
  sort: sortOptions[0],
  booked: JSON.parse(localStorage.getItem('booked') || JSON.stringify([])),
  userEmail: localStorage.getItem('userEmail') || '',
  password: localStorage.getItem('token2') || '',
  isStaySignedIn: Boolean(localStorage.getItem('staySignedIn')),
  isScrollScreen: null,
  floor: 'All floors',
  qtConfig: localStorage.getItem('qtConfig') ? JSON.parse(localStorage.getItem('qtConfig') || '') : null,
  isLoadingImages: false,
  updatesSeen: JSON.parse(localStorage.getItem('updates_seen') || '[]'),
  cart: JSON.parse(localStorage.getItem('cart') || '[]'),
};

export const counterSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setRefreshRooms: (state, action: PayloadAction<boolean>) => {
      state.refreshRooms = action.payload;
    },
    setRooms: (state, action: PayloadAction<RoomType[] | null>) => {
      if (action.payload) {
        if (!state.displayRooms) {
          state.displayRooms = [];
        }
        state.displayRooms.forEach((room, i) => {
          // Find similar rooms in displayRooms and payload and replace
          // the displayload data with the payload data so displayRoom maintains it's order
          if (state.displayRooms && state.displayRooms[i] && action.payload) {
            const thisRoom = action.payload.find((r) => r.id === state.displayRooms?.[i].id);
            state.displayRooms[i] = thisRoom || action.payload[0];
          }
        });
      } else {
        state.displayRooms = null;
      }
      state.rooms = action.payload;
    },
    setRoomsNoDisplay: (state, action: PayloadAction<RoomType[] | null>) => {
      state.rooms = action.payload;
    },
    setDisplayRooms: (state, action: PayloadAction<RoomType[] | null>) => {
      state.displayRooms = action.payload;
    },
    setDisplayFromRooms: (state, action: PayloadAction<RoomType[] | null>) => {
      if (action.payload) {
        if (!state.displayRooms) {
          state.displayRooms = [];
        }
        state.displayRooms.forEach((room, i) => {
          // Find similar rooms in displayRooms and payload and replace...
          // ...the displayload data with the payload data so displayRoom maintains it's order
          if (state.displayRooms && state.displayRooms[i] && action.payload) {
            const thisRoom = action.payload.find((r) => r.id === state.displayRooms?.[i].id);
            state.displayRooms[i] = thisRoom || action.payload[0];
          }
        });
      } else {
        state.displayRooms = null;
      }
    },
    setBookersEmail: (state, action: PayloadAction<string>) => {
      state.bookersEmail = action.payload;
      localStorage.setItem('bookersEmail', action.payload);
    },
    setBookersName: (state, action: PayloadAction<string>) => {
      state.bookersName = action.payload;
    },
    setBookDays: (state, action: PayloadAction<string>) => {
      state.bookDays = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    setBooked: (state, action: PayloadAction<Booked>) => {
      state.booked = [...state.booked, action.payload];
      localStorage.setItem('booked', JSON.stringify([...state.booked, action.payload]));
    },
    setBookedFull: (state, action: PayloadAction<Booked[]>) => {
      state.booked = action.payload;
      localStorage.setItem('booked', JSON.stringify(action.payload));
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
      localStorage.setItem('userEmail', action.payload);
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
      localStorage.setItem('token2', action.payload);
    },
    setIsStaySignedIn: (state, action: PayloadAction<boolean>) => {
      state.isStaySignedIn = action.payload;
      if (action.payload) {
        localStorage.setItem('staySignedIn', 'Yes');
      } else {
        localStorage.removeItem('staySignedIn');
      }
    },
    setIsScrollScreen: (state, action: PayloadAction<boolean>) => {
      state.isScrollScreen = action.payload;
    },
    setFloor: (state, action: PayloadAction<string>) => {
      state.floor = action.payload;
    },
    setQtConfig: (state, action: PayloadAction<QtConfig | null>) => {
      state.qtConfig = action.payload;
      if (action.payload) {
        localStorage.setItem('qtConfig', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('qtConfig');
      }
    },
    setisLoadingImages: (state, action: PayloadAction<boolean>) => {
      state.isLoadingImages = action.payload;
    },
    setUpdatesSeen: (state, action: PayloadAction<string[]>) => {
      state.updatesSeen = action.payload;
      localStorage.setItem('updates_seen', JSON.stringify(action.payload));
    },
    setCart: (state, action: PayloadAction<BookRoomDetails[]>) => {
      state.cart = action.payload;
      localStorage.setItem('cart', JSON.stringify(action.payload));
    },
  },
});

export const {
  setRefreshRooms,
  setRooms,
  setRoomsNoDisplay,
  setDisplayRooms,
  setDisplayFromRooms,
  setBookersEmail,
  setBookersName,
  setBookDays,
  setSearch,
  setSort,
  setBooked,
  setBookedFull,
  setUserEmail,
  setPassword,
  setIsStaySignedIn,
  setIsScrollScreen,
  setFloor,
  setQtConfig,
  setisLoadingImages,
  setUpdatesSeen,
  setCart,
} = counterSlice.actions;

export const selectRefreshRooms = (state: RootState) => state.app.refreshRooms;
export const selectRooms = (state: RootState) => state.app.rooms;
export const selectDisplayRooms = (state: RootState) => state.app.displayRooms;
export const selectBookersEmail = (state: RootState) => state.app.bookersEmail;
export const selectBookersName = (state: RootState) => state.app.bookersName;
export const selectBookDays = (state: RootState) => state.app.bookDays;
export const selectSearch = (state: RootState) => state.app.search;
export const selectSort = (state: RootState) => state.app.sort;
export const selectBooked = (state: RootState) => state.app.booked;
export const selectUserEmail = (state: RootState) => state.app.userEmail;
export const selectPassword = (state: RootState) => state.app.password;
export const selectIsStaySignedIn = (state: RootState) => state.app.isStaySignedIn;
export const selectIsScrollScreen = (state: RootState) => state.app.isScrollScreen;
export const selectFloor = (state: RootState) => state.app.floor;
export const selectQtConfig = (state: RootState) => state.app.qtConfig;
export const selectIsLoadingImages = (state: RootState) => state.app.isLoadingImages;
export const selectUpdatesSeen = (state: RootState) => state.app.updatesSeen;
export const selectCart = (state: RootState) => state.app.cart;

export default counterSlice.reducer;

export type AddEditApiBody = {
  name: string,
  description: string | null,
  floor: string,
  price: string
  origPrice: string,
  onHold: string | null,
  imgFile: string | null,
  imgFiles: string | null,
  perks: string,
  id?: string | null,
  origName?: string | null,
};

export const addRoom = (body: AddEditApiBody) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addroom',
      body,
      method: 'POST',
    }),
  );
  return res;
};

export const getRooms =
() => async (dispatch: Function, getState: Function): Promise<IResponse> => {
  const state = getState();
  const { isStaff } = state.homepage;

  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'rooms',
      body: { isStaff },
      method: 'POST',
    }),
  );
  return res;
};

export const getRoomImages = () => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'roomimages',
      method: 'GET',
    }),
  );
  return res;
};

export const getRoomImage = (id: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'roomimage',
      body: { id },
      method: 'POST',
    }),
  );
  return res;
};

export type BookRoomDetails = {
  id: string,
  name: string,
  days: string,
  token: string | null,
  number?: string | null,
  refundAmount?: string | null,
  email?: string,
  hours?: string,
  mins?: string,
  secs?: string,
  email2?: string,
  price: string,
  roomName: string,
  isDeskBooking?: boolean,
  isEditingBooking?: boolean,
}

export const bookRoom =
(body: BookRoomDetails[]) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'book',
      body: { bookingDetails: body },
      method: 'PATCH',
    }),
  );
  return res;
};

export const editRoom =
(body: AddEditApiBody) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'editroom',
      body,
      method: 'PATCH',
    }),
  );
  return res;
};

export const deleteRoom = (id: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deleteroom',
      body: { id },
      method: 'DELETE',
    }),
  );
  return res;
};

export const getQtConfig = () => async (dispatch: Function) : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'qtauth',
      method: 'GET'
    }),
  );
  return res;
};

export const transfer = () => async (dispatch: Function) : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'transfer',
      method: 'POST',
    }),
  );
  return res;
};
