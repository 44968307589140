import styled from 'styled-components';
import client from 'client.json';
import { roomGridRes1, roomGridRes2, roomGridRes3 } from './Rooms.styled';
import Color from 'color';

export const Container = styled.div<{ token: boolean }>`
  width: 100%;
  max-width: 700px;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  border: 1px solid #999999;
  margin-top: 17px;
  margin-bottom: 16px;
  @media(max-width: ${roomGridRes1}) {
    margin-top: 11px;
    margin-bottom: 11px;
  }
  @media(max-width: ${roomGridRes2}) {
    margin-top: 17px;
    margin-bottom: 16px;
  }
  @media(max-width: ${roomGridRes3}) {
    margin-top: 11px;
    margin-bottom: 11px;
  }
  @media(max-width: 438px) {
    margin-top: ${({ token }) => token && '10px'};
  }
`;

const floorRemoveRes = '550px';
export const SearchCont = styled.div`
  width: 100%;
  background: ${client.color};
  padding: 8px 10px;
  padding-bottom: 9px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media(max-width: 500px) {
    padding: 7px 9px;
    padding-bottom: 8px;
    padding-bottom: 5px;
  }
  @media(max-width: ${floorRemoveRes}) {
    padding-bottom: 6px;
  }
`;

export const FlexCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const InputCont = styled.div`
  width: 47.5%;
  max-width: 320px;
  position: relative;
  @media(max-width: ${floorRemoveRes}) {
    width: 66%;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding-left: 40px;
  padding-right: 9px;
  border-radius: 3px;
  border: 1px solid #999999;
  outline: 0;
  font-size: 14px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 300;
`;

export const FloorCont = styled.div`
  flex: 1;
  height: 40px;
  @media(max-width: ${floorRemoveRes}) {
    display: none;
  }
`;

export const FloorCont1 = styled.div`
  display: none;
  width: 38%;
  max-width: 130px;
  height: 36px;
  @media(max-width: ${floorRemoveRes}) {
    display: block;
  }
`;

export const SortCont = styled.div`
  width: 29.5%;
  max-width: 200px;
  margin-left: auto;
  @media(max-width: 560px) {
    width: 32%;
  }
`;

export const Select = styled.select`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding-left: 7px;
  font-size: 14px;
  color: black;
  border-radius: 3px;
  border-color: #999999;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 300;
`;

export const Select1 = styled(Select)`
  height: 100%;
`;

export const OrderBySect1 = styled.div`
  gap: 3px;
  display: none;
  margin: 0;
  padding: 0;
  height: 17px;
  @media(max-width: ${floorRemoveRes}) {
    display: flex;
    align-items: center;
  }
`;

export const Input11 = styled.input`
  height: 15px;
  width: 15px;
  margin-left: 1px;
  cursor: pointer;
  color: #525252;
`;

export const OrderBy1 = styled.div`
  margin-top: -2px;
  color: #7a7a7a;
  font-size: 11px;
  @media(max-width: ${floorRemoveRes}) {
    margin-top: 1px;
  }
`;

export const Result = styled.div`
  font-size: 13px;
  margin: auto;
  padding: 9px 10px;
  border-top: none;
  border-radius: 4px;
  color: #7a7a7a;
  display: flex;
  align-items: flex-end;
  gap: 9px;
  @media(max-width: ${floorRemoveRes}) {
    align-items: flex-end;
  }
  @media(max-width: 335px) {
    font-size: 10px;
  }
`;

export const OrderBySect = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  @media(max-width: ${floorRemoveRes}) {
    display: none;
  }
`;

export const Input1 = styled.input`
  height: 14px;
  width: 14px;
  margin: 0;
  cursor: pointer;
  color: #525252;
`;

export const OrderBy = styled.div`
  margin-top: -1px;
`;

export const ResultText = styled.div`
  margin-left: auto;
  font-size: 11px;
  transform: translate(-1px, 0.5px);
  @media(max-width: ${floorRemoveRes}) {
    font-weight: 300;
    font-size: 10px;
    transform: translateY(-1px, 2.5px);
  }
`;

export const Floor = styled.span`
  margin-top: -1px;
  color: ${Color('lightgrey').darken(0.3).toString()};
  font-weight: 300;
  @media(max-width: ${floorRemoveRes}) {
    display: none;
  }
`;
