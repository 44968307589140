import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import React from 'react';
import { RootState } from 'redux/store';

export type Metric = {
  startDate: Date,
  endDate: Date
}
export type ModalParts = {
  component: React.JSX.Element,
  exitOnBgClick?: Function,
}
export interface ViewsState {
  socket: any,
  isSocketConnected: boolean,
  metricTime: Metric | null,
  debugText: any[]
}

const earlyStartD = new Date();
earlyStartD.setHours(0);
earlyStartD.setMinutes(0);
earlyStartD.setSeconds(0);
earlyStartD.setMilliseconds(0);
const lateEndD = new Date();
lateEndD.setHours(23);
lateEndD.setMinutes(59);
lateEndD.setSeconds(59);
lateEndD.setMilliseconds(999);

const initialState: ViewsState = {
  socket: null,
  isSocketConnected: true,
  metricTime: {
    startDate: earlyStartD,
    endDate: lateEndD,
  },
  debugText: [],
};

export const counterSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setIsSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.isSocketConnected = action.payload;
    },
    setMetricTime: (state, action: PayloadAction<Metric | null>) => {
      state.metricTime = action.payload;
    },
    setDebugText: (state, action: PayloadAction<any[]>) => {
      state.debugText = action.payload;
    }
  },
});

export const {
  setSocket,
  setIsSocketConnected,
  setMetricTime,
  setDebugText
} = counterSlice.actions;

export const selectSocket = (state: RootState) => state.views.socket;
export const selectIsSocketConnected = (state: RootState) => state.views.isSocketConnected;
export const selectMetricTime = (state: RootState) => state.views.metricTime;
export const selectDebugText = (state: RootState) => state.views.debugText;

export default counterSlice.reducer;
