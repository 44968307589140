import { callEndpoint } from 'redux/global';
import { IResponse } from 'global/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Log } from './hooks';
import { Metric } from 'pages/redux';

export interface LogsState {
  logs: Log[] | null,
  singleLogTime: Metric | null,
}

const initialState: LogsState = {
  logs: null,
  singleLogTime: null,
};

export const counterSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLogs: (state, action: PayloadAction<Log[] | null>) => {
      state.logs = action.payload;
    },
    setSingleLogTime: (state, action: PayloadAction<Metric | null>) => {
      state.singleLogTime = action.payload;
    }
  },
});

export const { setLogs, setSingleLogTime } = counterSlice.actions;

export const selectLogs = (state: RootState) => state.logs.logs;
export const selectSingleLogTime = (state: RootState) => state.logs.singleLogTime;

export default counterSlice.reducer;

export const getLogs = () => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'getlogs',
      method: 'GET',
    }),
  );
  return res;
};
