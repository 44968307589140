import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export type MoveType = 'month' | 'week' | 'day';

interface MetricsState {
  jointMove: string,
  moveType: MoveType
}

const initialState: MetricsState = {
  jointMove: localStorage.getItem('joint_move') || '',
  moveType: localStorage.getItem('move_type') as MoveType || 'day'
};

export const counterSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setJointMove: (state, action: PayloadAction<string>) => {
      state.jointMove = action.payload;
      localStorage.setItem('joint_move', action.payload);
    },
    setMoveType: (state, action: PayloadAction<MoveType>) => {
      state.moveType = action.payload;
      localStorage.setItem('move_type', action.payload);
    }
  },
});

export const { setJointMove, setMoveType } = counterSlice.actions;

export const selectJointMove = (state: RootState) => state.metrics.jointMove;
export const selectMoveType = (state: RootState) => state.metrics.moveType;

export default counterSlice.reducer;
