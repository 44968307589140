import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Loader from 'pages/components/Loader/Loader';
import { useAppSelector } from 'redux/hooks';
import {
  selectPermission, selectToken,
} from 'redux/global';

const Homepage = lazy(() => import('pages/Homepage/Homepage'));
const App = lazy(() => import('pages/App/App'));
const Logs = lazy(() => import('pages/Logs/Logs'));
const Metrics = lazy(() => import('pages/Metrics/Metrics'));
const Settings = lazy(() => import('pages/Settings/Settings'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const Forgot = lazy(() => import('pages/Forgot/Forgot'));
const SetPassword = lazy(() => import('pages/SetPassword/SetPassword'));
const Register = lazy(() => import('pages/Register/Register'));
const RegisterPassword = lazy(() => import('pages/RegisterPassword/RegisterPassword'));
const AddEditRoom = lazy(() => import('pages/AddEditRoom/AddEditRoom'));
const Navigation = lazy(() => import('pages/Navigation/Navigation'));
const Cart = lazy(() => import('pages/Cart/Cart'));

(document.body.style as any).zoom = '100%';

const Routing = function Routing() {
  const token = useAppSelector(selectToken);
  const permission = useAppSelector(selectPermission);

  if (!token || permission === 0) {
    return (
      <Router>
        <Suspense fallback={<Loader />}>
          <Navigation>
            <Switch>
              <Route exact path="/">
                <Homepage />
              </Route>
              {
                !token && (
                  <Route exact path="/cart">
                    <Cart />
                  </Route>
                )
              }
              <Route exact path="/rooms">
                <App />
              </Route>
              <Route exact path="/rooms/:toBook">
                <App />
              </Route>
              <Route exact path="/forgot">
                <Forgot />
              </Route>
              <Route exact path="/forgot/:key">
                <SetPassword />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/register/:key">
                <RegisterPassword />
              </Route>
              <Route exact path="*">
                <NotFound />
              </Route>
            </Switch>
          </Navigation>
        </Suspense>
      </Router>
    );
  }

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Navigation>
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route exact path="/rooms">
              <App />
            </Route>
            <Route exact path="/rooms/:toBook">
              <App />
            </Route>
            <Route exact path="/logs">
              <Logs />
            </Route>
            <Route exact path="/logs/:action">
              <Logs />
            </Route>
            <Route exact path="/metrics">
              <Metrics />
            </Route>
            <Route exact path="/settings">
              <Settings />
            </Route>
            <Route exact path="/add">
              <AddEditRoom />
            </Route>
            <Route exact path="/edit/:id">
              <AddEditRoom />
            </Route>
            <Route exact path="/forgot">
              <Forgot />
            </Route>
            <Route exact path="/forgot/:key">
              <SetPassword />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/register/:key">
              <RegisterPassword />
            </Route>
            <Route exact path="*">
              <NotFound />
            </Route>
          </Switch>
        </Navigation>
      </Suspense>
    </Router>
  );
};

export default Routing;
